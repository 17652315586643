import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Link,
} from "@chakra-ui/react"
import React, { useRef } from "react"

interface AnnouncementDialogProps {
  isOpen: boolean
  onClose: () => void
}

export const AnnouncementDialog = (props: AnnouncementDialogProps) => {
  const { isOpen, onClose } = props

  const cancelRef = useRef()

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent maxW="3xl">
          <AlertDialogHeader fontSize="3xl" mb={0} pb={0} fontWeight="bold">
            Niftory is shutting down on August 14th
          </AlertDialogHeader>

          <AlertDialogCloseButton />

          <AlertDialogBody>
            We're sorry to announce that we're shutting down Niftory and the admin portal on August
            14th. This was a hard decision and we appreciate you all being on the journey with us to
            make building web3 apps more accessible. The Niftory contracts will not be upgraded to{" "}
            <Link
              href="https://flow.com/upgrade/crescendo/cadence-1"
              target="_blank"
              color="blue.600"
            >
              Cadence 1.0
            </Link>
            .
            <Box mt="4">
              This means that unfortunately, Niftory NFTs will no longer continue to operate. Reach
              out to us at &nbsp;
              <Link href="mailto:support@niftory.com" target="_blank" color="blue.600">
                support@niftory.com
              </Link>
              &nbsp; if you'd like to know more or chat with us.
            </Box>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
