import { ChakraProvider, extendTheme, useDisclosure } from "@chakra-ui/react"
import Head from "next/head"
import { useRouter } from "next/router"
import { SessionProvider } from "next-auth/react"
import posthog from "posthog-js"
import React, { useEffect } from "react"
import { WalkthroughProvider } from "src/components/app/internal/walkthrough/WalkthroughProvider"
import Walkthrough from "src/components/app/internal/walkthrough/WalkthroughWrapper"
import { AnnouncementDialog } from "src/components/ui/AnnouncementDialog"
import { SESSION_REFETCH_INTERVAL_MINUTES } from "src/lib/auth/constants"
import { ApiProvider } from "src/lib/providers/ApiProvider"
import { AppProvider } from "src/lib/providers/AppProvider"
import { Auth } from "src/lib/providers/Auth"
import { FeatureFlagProvider } from "src/lib/providers/FeatureFlagProvider"
import { GraphQLProvider } from "src/lib/providers/GraphQLProvider"
import { NetworkProvider } from "src/lib/providers/NetworkProvider"
import { NiftoryClientProvider } from "src/lib/providers/NiftoryClientProvider"

const theme = extendTheme({
  colors: {
    brand: {
      100: "#805AD5", // purple.500
      200: "#B794F4", // purple.300
    },
    content: {
      100: "white",
      200: "#E2E8F0", // gray.200
      300: "#2D3748", // gray.700
    },
    page: {
      background: "white",
      accent: "#805AD5",
      sidebarColor: "#2D3748", //gray.700
      buttonOnSidebarColor: "#4A5568",
      blackAccent: "RGBA(0, 0, 0, 0.80)",
    },
    footer: {
      text: "white",
    },
  },
  shadow: {},
  styles: {
    global: {
      body: {
        fontWeight: "light",
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        colorScheme: "gray",
      },
      variants: {
        primary: {
          size: "lg",
          colorScheme: "blackAlpha",
          bgColor: "black",
          color: "white",
          _hover: {
            bg: "gray.700",
          },
          _disabled: {
            bg: "gray.400",
            color: "black",
          },
        },

        primaryBold: {
          borderRadius: "lg",
          bgColor: "blackAlpha.800",
          textColor: "white",
          fontSize: { base: "sm", md: "md" },
          size: "md",
          fontWeight: "semibold",
          _hover: {
            bg: "blackAlpha.900",
            _loading: { bgColor: "blackAlpha.900" },
          },
        },
        secondaryBold: {
          borderRadius: "lg",
          borderWidth: "1.5px",
          borderColor: "blackAlpha.800",
          textColor: "black",
          fontSize: { base: "sm", md: "md" },
          size: "md",
          fontWeight: "semibold",
          _hover: { borderColor: "blackAlpha.900", borderWidth: "2px" },
        },
        primaryOnDark: {
          borderRadius: "lg",
          // borderWidth: "1.5px",
          borderColor: "white",
          bgColor: "page.sidebarColor",
          textColor: "white",
          fontWeight: "semibold",
          size: "md",
          _hover: { borderWidth: "2px", fontWeight: "bold" },
        },
      },
    },
  },
})

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter()
  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      autocapture: false,
      loaded: (posthog) => {
        if (process.env.NODE_ENV === ("development" || "test")) {
          posthog.opt_out_capturing()
        }
      },
    })
    const handleRouteChange = () => {
      if (typeof window !== "undefined") {
        posthog.capture("$pageview")
      }
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  return (
    <FeatureFlagProvider>
      <Head>
        <title>Niftory: Powerful Web3 APIs and Infrastructure</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Niftory Admin: Powerful Web3 APIs and Infrastructure" />
        <meta
          property="og:description"
          content="Login to Niftory Admin and build your first dApp in hours.
          Niftory's APIs make it easy for any developer to quickly integrate NFTs, digital wallets and
          other web3 concepts into their applications, with or without blockchain expertise.
          Get your free API key today. "
        />
        <meta property="og:image" content={"/niftory_preview.jpg"} />
      </Head>
      <ApiProvider featureFlag="api_admin">
        <SessionProvider session={session} refetchInterval={60 * SESSION_REFETCH_INTERVAL_MINUTES}>
          <NetworkProvider featureFlag="mainnet_toggle" useNetworkValue={false}>
            <Auth requireAuth={Component.auth}>
              <GraphQLProvider route="admin">
                <ChakraProvider theme={theme}>
                  <NiftoryClientProvider>
                    <AppProvider role={Component.auth?.role}>
                      <WalkthroughProvider>
                        <AnnouncementDialog isOpen={isOpen} onClose={onClose} />
                        <Walkthrough />
                        <Component {...pageProps} />
                      </WalkthroughProvider>
                    </AppProvider>
                  </NiftoryClientProvider>
                </ChakraProvider>
              </GraphQLProvider>
            </Auth>
          </NetworkProvider>
        </SessionProvider>
      </ApiProvider>
    </FeatureFlagProvider>
  )
}
